/*
Author: Nishant Singh
Description: All the constant data which is used in most component
*/

import { isWebPlatform } from './screens';

// .......Don't change the sequence of phaseList
const Config = require('~config/default.env').default;
export const phaseList = [
  { title: 'Mengandung', value: 'expecting', status: 'Mengandung' },
  { title: 'Menyusui', value: 'breastfeeding', status: 'Menyusui' },
  {
    title: 'Memiliki Anak Batita',
    value: 'toddler',
    status: 'Memiliki Anak Batita',
  },
  {
    title: 'Memiliki Anak 3+ Tahun',
    value: 'three_year_kid',
    status: 'Memiliki Anak 3+ Tahun',
  },
  { title: 'Belum Memiliki Anak', value: 'no', status: 'Amazing Woman' },
];

export const motherPhaseList = {
  expecting: { title: 'Mengandung', value: 'expecting', status: 'Mengandung' },
  breastfeeding: {
    title: 'Menyusui',
    value: 'breastfeeding',
    status: 'Menyusui',
  },
  toddler: {
    title: 'Memiliki Anak Batita',
    value: 'toddler',
    status: 'Memiliki Anak Batita',
  },
  three_year_kid: {
    title: 'Memiliki Anak 3+ Tahun',
    value: 'three_year_kid',
    status: 'Memiliki Anak 3+ Tahun',
  },
  no: {
    title: 'Belum Memiliki Anak',
    value: 'no',
    status: { Female: 'Amazing Woman', Male: 'Amazing Man' },
  },
};

export const stateTitle = {
  province: 'Pilih Provinsi',
  district: 'Pilih Kecamatan',
  city: 'Pilih Kota/ Kabupaten',
  'birth-confirmation': 'Konfirmasi Kelahiran',
  'change-username': 'Change Username',
  'change-status': 'Change Status',
  'add-child': 'Masukkan Profil Anak',
  'add-prediction': 'Mengandung',
};

export const optionTypes = [
  { key: 'country', state: 'countries', title: 'Pilih Negara', clear: false },
  {
    key: 'province',
    state: 'provinces',
    title: 'Pilih Provinsi',
    clear: { city: {}, district: {} },
  },
  {
    key: 'city',
    state: 'cities',
    title: 'Pilih Kota/ Kabupaten',
    clear: { district: {} },
  },
  {
    key: 'district',
    state: 'districts',
    title: 'Pilih Kecamatan',
    clear: false,
  },
];

export const SOC = {
  android: 'lulla-android',
  ios: 'lulla-ios',
  web: 'lulla-web-mobile',
};

export const ERROR_CODE = {
  404: 'error_400',
  500: 'error_500',
  503: 'error_503',
};
export const OrderStatus = {
  payment_accepted: {
    borderColor: '#ABE3E4',
    backgroundColor: '#ABE3E4',
  },
  waiting_payment: {
    borderColor: '#A775C8',
    backgroundColor: '#F5EEF9',
  },
  order_in_progress: {
    backgroundColor: '#FCEADE',
    borderColor: '#E3CDB7',
  },
  shipped: {
    backgroundColor: '#D7CDF5',
    borderColor: '#AD9EDA',
  },
  delivered: {
    backgroundColor: '#FCF6BB',
    borderColor: '#E6C358',
  },
  cancelled: {
    backgroundColor: '#F7D6D9',
    borderColor: '#F15B68',
  },
};

export const Duration = [
  {
    isSelected: false,
    text: 'Less than 1 Week',
  },
  {
    isSelected: false,
    text: '1 Week - 1 Month',
  },
  {
    isSelected: false,
    text: '1 - 3 Months',
  },
  {
    isSelected: false,
    text: '3 - 6 Months',
  },
  {
    isSelected: false,
    text: '6 Months - 1 Year',
  },
  {
    isSelected: false,
    text: '> 1 Year',
  },
];

export const Images = [
  require('~assets/img/product/star-gray.svg'),
  require('~assets/img/product/star-purple.svg'),
  require('~assets/img/dummy_product.png'),
  require('~assets/img/product/wishlist-icon-active.svg'),
  require('~assets/img/product/wishlist-icon.svg'),
  require('~assets/img/product/text-bundles.png'),
  require('~assets/img/product/text-exclusive.png'),
  require('~assets/img/common/header-back-icon.svg'),
  require('~assets/img/common/arrow-back.png'),
  require('~assets/img/smartphone.svg'),
];

const iconLilla = `${Config.LULLA_WEB_URL + require('~assets/icon.jpg')}`;
const imageLilla = `${
  Config.LULLA_WEB_URL + require('~assets/logo-lilla-square.jpg')
}`;

export const DEFAULT_META_TAG = {
  title: 'Lilla - The First Ultimate App for Moms',
  description:
    'Bebas ongkir se-Indonesia* & extra voucher untuk  belanja kebutuhan ibu, dari kehamilan, menyusui, pasca-melahirkan hingga kebutuhan Si Kecil dari brand-brand terpercaya, 100% BPOM & SNI.',
  keywords:
    'online,store,terpercaya,beauty,parenting,skincare,kehamilan,menyusui,ibu hamil,ahli anak,mom and baby,promo,perkembangan anak,toko online',
  image: imageLilla,
  logo: iconLilla,
  username: '@sociolla_id',
  url: isWebPlatform ? window.location.href : Config.LULLA_WEB_URL,
};

export const egiftInfo =
  'Pengiriman kartu E-gift card tidak akan dikenakan biaya karena akan dikirimkan melalui email penerima!';
export const egiftConfirm =
  'E-Gift Card akan dikirimkan melalui email/nomor handphone atau keduanya sesuai dengan data yang kamu isi.';
export const downloadLink = {
  appStore: 'https://apps.apple.com/id/app/lilla/id1572422418',
  playStore:
    'https://play.google.com/store/apps/details?id=com.socialbella.lillaapps',
};
export const footerButtons = [
  {
    key: 'app-store',
    url: downloadLink.appStore,
    src: require('~assets/img/home/footer/btn-app-store.png'),
  },
  {
    key: 'play-store',
    url: downloadLink.playStore,
    src: require('~assets/img/home/footer/btn-google-play.png'),
  },
];
export const alreadyLogin = 'You have already logged in.';
export const downloadURL = 'https://lillapp.app.link/DownloadNow';

export const recommend = [
  { isSelected: false, text: 'Yes absolutely!', value: 'yes' },
  { isSelected: false, text: 'No', value: 'no' },
];

export const repurchase = [
  { isSelected: false, text: 'Yes', value: 'yes' },
  { isSelected: false, text: 'Maybe', value: 'maybe' },
  { isSelected: false, text: 'No', value: 'no' },
];

export const trustedReviewTitle = require('~assets/img/my-reviews/trusted-review.svg');
export const iconInfoAltDangerSource = require('~assets/img/common/icon_info_alt_danger.png');

export const starGreySource = require('~assets/img/my-reviews/star-inactive.png');
export const starPurpleSource = require('~assets/img/my-reviews/star-big.png');
export const iconAddPhotoSource = require('~assets/img/my-reviews/icon-add-photo.png');
export const iconAddPhotoSmallSource = require('~assets/img/my-reviews/icon-add-photo-small.png');
export const iconClearPhotoSource = require('~assets/img/my-reviews/clear-photo.png');
export const iconAddPhotoMoreSource = require('~assets/img/my-reviews/icon-add-more.png');

export const iconRecommendMaybeSource = require('~assets/img/my-reviews/icon-recommend-maybe.png');
export const iconRecommendYesSource = require('~assets/img/my-reviews/icon-recommend-yes.png');
export const iconRecommendNoSource = require('~assets/img/my-reviews/icon-recommend-no.png');
export const iconRecommendMaybeSourceActive = require('~assets/img/my-reviews/icon-recommend-maybe-active.png');
export const iconRecommendYesSourceActive = require('~assets/img/my-reviews/icon-recommend-yes-active.png');
export const iconRecommendNoSourceActive = require('~assets/img/my-reviews/icon-recommend-no-active.png');

export const rates = {
  5: [1, 2, 3, 4, 5],
  4: [1, 2, 3, 4],
  3: [1, 2, 3],
  2: [1, 2],
  1: [1],
};

export const limitProduct = [16, 32, 50];

export const skinTypeDataForMoms = {
  skincare: [
    {
      name: 'Normal skin',
      value: 'kulit-normal',
      code: 'normal',
      isSelected: false,
    },
    {
      name: 'Dry skin',
      value: 'kulit-kering',
      code: 'dry',
      isSelected: false,
    },
    {
      name: 'Oily skin',
      value: 'kulit-berminyak',
      code: 'oily',
      isSelected: false,
    },
    {
      name: 'Sensitive skin',
      value: 'kulit-sensitif',
      code: 'sensitive',
      isSelected: false,
    },
    {
      name: 'Combination',
      value: 'combination',
      code: 'combination',
      isSelected: false,
    },
  ],
  bathBody: [
    {
      name: 'Normal',
      value: 'normal',
      code: 'normal',
      isSelected: false,
    },
    {
      name: 'Dry / Kering',
      value: 'dry',
      code: 'dry',
      isSelected: false,
    },
    {
      name: 'Oily / Berminyak',
      value: 'oily',
      code: 'oily',
      isSelected: false,
    },
    {
      name: 'Sensitive',
      value: 'sensitive',
      code: 'sensitive',
      isSelected: false,
    },
    {
      name: 'Combination',
      value: 'combination',
      code: 'combination',
      isSelected: false,
    },
  ],
  fragrance: [
    {
      name: 'Spray',
      value: 'spray',
      code: 'spray',
      isSelected: false,
    },
    {
      name: 'Roll-On',
      value: 'roll-on',
      code: 'roll-on',
      isSelected: false,
    },
    {
      name: 'Stick',
      value: 'stick',
      code: 'stick',
      isSelected: false,
    },
  ],
};

export const concernDataForMoms = {
  skincare: [
    {
      name: 'Acne & Blemishes',
      value: 'acne-blemishes',
      code: 'ab',
      isSelected: false,
    },
    {
      name: 'Aging',
      value: 'aging',
      code: 'aging',
      isSelected: false,
    },
    {
      name: 'Blackheads & Visible Pores',
      value: 'blackheads-visible-pores',
      code: 'bvp',
      isSelected: false,
    },
    {
      name: 'Dullness',
      value: 'dullness',
      code: 'dullness',
      isSelected: false,
    },
    {
      name: 'Dark Spots',
      value: 'dark-spots',
      code: 'ds',
      isSelected: false,
    },
    {
      name: 'Dark-Circles',
      value: 'dark-circles',
      code: 'dc',
      isSelected: false,
    },
  ],
  hairCare: [
    {
      name: 'Hair Loss / Rambut Rontok',
      value: 'hair-loss',
      code: 'hl',
      isSelected: false,
    },
    {
      name: 'Dandruff / Ketombe',
      value: 'dandruff',
      code: 'dandruff',
      isSelected: false,
    },
    {
      name: 'Dry Scalp',
      value: 'dry-scalp',
      code: 'ds',
      isSelected: false,
    },
    {
      name: 'Oiliness / Rambut-berminyak',
      value: 'oiliness',
      code: 'oiliness',
      isSelected: false,
    },
    {
      name: 'Normal',
      value: 'normal',
      code: 'normal',
      isSelected: false,
    },
  ],
  bathBody: [
    {
      name: 'Stretch Marks',
      value: 'stretch-marks',
      code: 'sm',
      isSelected: false,
    },
    {
      name: 'Cellulite',
      value: 'cellulite',
      code: 'cellulite',
      isSelected: false,
    },
    {
      name: 'Sore, Dry & Flakky Nipple',
      value: 'sore-dry-flakky-nipple',
      code: 'sdfn',
      isSelected: false,
    },
  ],
  fragrance: [
    {
      name: 'Floral',
      value: 'floral',
      code: 'floral',
      isSelected: false,
    },
    {
      name: 'Fruity',
      value: 'fruity',
      code: 'fruity',
      isSelected: false,
    },
    {
      name: 'Fresh',
      value: 'fresh',
      code: 'fresh',
      isSelected: false,
    },
    {
      name: 'Earthy-and-woody',
      value: 'earthy-and-woody',
      code: 'eaw',
      isSelected: false,
    },
    {
      name: 'Powdery',
      value: 'powdery',
      code: 'powdery',
      isSelected: false,
    },
  ],
  oralCare: [
    {
      name: 'Sensitive Teeth',
      value: 'sensitive-teeth',
      code: 'st',
      isSelected: false,
    },
    {
      name: 'Cavity',
      value: 'cavity',
      code: 'cavity',
      isSelected: false,
    },
    {
      name: 'Dental Plaque',
      value: 'dental-plaque',
      code: 'dp',
      isSelected: false,
    },
    {
      name: 'Gum Care',
      value: 'gum-care',
      code: 'gc',
      isSelected: false,
    },
    {
      name: 'Freshens Breath/ Fresh-Breath',
      value: 'freshens-breath',
      code: 'fb',
    },
  ],
};

export const skinTypeDataForKids = {};

export const concernDataForKids = {
  oralCare: [
    {
      name: 'Cavity',
      value: 'cavity',
      code: 'cavity',
      isSelected: false,
    },
    {
      name: 'Dental Plaque',
      value: 'dental-plaque',
      code: 'dp',
      isSelected: false,
    },
    {
      name: 'Gum Care',
      value: 'gum-care',
      code: 'gc',
      isSelected: false,
    },
  ],
  babyCare: [
    {
      name: 'Eczema',
      value: 'eczema',
      code: 'eczema',
      isSelected: false,
    },
  ],
  toiletries: [
    {
      name: 'Bug-bite',
      value: 'bug-bite',
      code: 'bb',
      isSelected: false,
    },
  ],
  diaper: [
    {
      name: 'Diaper-rash',
      value: 'diaper-rash',
      code: 'dr',
      isSelected: false,
    },
  ],
  hairCare: [
    {
      name: 'Hair Growth',
      value: 'hair-growth',
      code: 'hg',
      isSelected: false,
    },
    {
      name: 'Dry & Tangled Hair',
      value: 'dry-tangled-hair',
      code: 'dth',
      isSelected: false,
    },
    {
      name: 'Eczema',
      value: 'eczema',
      code: 'eczema',
      isSelected: false,
    },
    {
      name: 'Cradle Cap',
      value: 'cradle-cap',
      code: 'cc',
      isSelected: false,
    },
  ],
};

export const childAge = [
  'Newborn (0-3 months)',
  'Bayi/Baby (4-12 months)',
  'Batita/Infant (1-3 years)',
  'Balita/Toddler (4-5 years)',
  'Kids (6+ years)',
];

export const motherhoodStage = [
  'Pregnancy',
  'Postpartum',
  'Breastfeeding',
  'Others',
];

export const catShowConcern = [
  'Skincare',
  'Hair Care',
  'Bath & Body',
  'Oral Care',
  'Baby Care',
  'Toiletries',
  'Diapers',
  'For Moms',
  'For Baby & Kids',
];

export const onlyGeneralFilters = [
  'Laundry & Detergent',
  'Sanitary',
  'Nursing & Feeding',
  'Accessories',
  'Nail Care',
  'Laundry & Cleaning',
  'Oral Care',
  'Diaper',
];

export const specialSectionDescribe = {
  Fragrance: [
    {
      nameSub: 'Type/Format',
      codeSub: 'Skin/Hair Type',
      pointSub: 'typeFormat',
      typeSection: 'SET_TYPE_FORMAT',
    },
    {
      nameSub: 'Scents',
      codeSub: 'Scent',
      pointSub: 'scents',
      typeSection: 'SET_SCENTS',
    },
  ],
  Travel: [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
    {
      nameSub: 'Type',
      codeSub: 'Travel Type',
      pointSub: 'typeFormat',
      typeSection: 'SET_TYPE_FORMAT',
    },
  ],
  'Baby Apparels': [
    {
      nameSub: 'Gender',
      codeSub: 'Gender',
      pointSub: 'gender',
      typeSection: 'SET_GENDER',
    },
    {
      nameSub: 'Size',
      codeSub: 'Size',
      pointSub: 'size',
      typeSection: 'SET_SIZE',
    },
  ],
  Fashion: [
    {
      nameSub: 'Size',
      codeSub: 'Size',
      pointSub: 'size',
      typeSection: 'SET_SIZE',
    },
  ],
  'Baby Cologne': [
    {
      nameSub: 'Scents',
      codeSub: 'Scent',
      pointSub: 'scents',
      typeSection: 'SET_SCENTS',
    },
  ],
  'Bedding & Bathroom': [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
  ],
  Toys: [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
  ],
  'Nursing & Feeding': [
    {
      nameSub: 'Material',
      codeSub: 'Material',
      pointSub: 'material',
      typeSection: 'SET_MATERIAL',
    },
    {
      nameSub: 'Flavours',
      codeSub: 'Flavours',
      pointSub: 'flavours',
      typeSection: 'SET_FLAVOURS',
    },
  ],
};

export const concernFilter = {
  'for-moms': {
    Skincare: [
      'acne-blemishes',
      'aging',
      'blackheads-visible-pores',
      'dullness',
      'dark-spots',
      'dark-circles',
    ],
    'Hair Care': [
      'rambut-rontok',
      'dandruff',
      'dry-scalp',
      'oiliness',
      'normal',
    ],
    'Bath & Body': ['stretch-marks', 'cellulite', 'sore-dry-flaky-nipple'],
    'Oral Care': [
      'sensitive-teeth',
      'cavity',
      'dental-plaque',
      'gum-care',
      'freshens-Breath',
    ],
  },
  'for-baby-kids': {
    'Oral Care': ['cavity', 'dental-plaque', 'gum-care'],
    'Baby Care': ['eczema'],
    Toiletries: ['bug-bite'],
    Diaper: ['diaper-rash'],
    'Hair Care': ['hair-growth', 'dry-tangled-hair', 'eczema', 'cradle-cap'],
  },
};

export const materialFilterNursing = [
  'Wood',
  'Sillicone',
  'Bamboo',
  'PP',
  'PPSU',
];
export const materialFilterToys = ['Wood', 'Silicone', 'Textile'];

export const howToListPayText = {
  bca_virtual_account: [
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'Transfer ATM',
      list: [
        'Masukkan kartu ke mesin ATM',
        'Masukkan 6 digit PIN kamu',
        'Pilih “Transaksi Lainnya”',
        'Pilih “Transfer”',
        'Pilih ke “ke Rekening BCA Virtual Account”',
        'Masukkan nomor BCA Virtual Account kamu, kemudian tekan “Benar”',
        'Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”',
        'Validasi pembayaran kamu. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya”',
        'Pembayaran kamu telah selesai. Tekan “Tidak” untuk menyelesaikan transaksi, atau tekan “Ya” untuk melakukan transaksi lainnya',
      ],
    },
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'BCA Mobile',
      list: [
        'Silahkan login pada aplikasi BCA Mobile',
        'Pilih “m-BCA”, lalu masukkan kode akses m-BCA',
        'Pilih “m-Transfer”',
        'Pilih “BCA Virtual Account”',
        'Pilih ke “ke Rekening BCA Virtual Account”',
        'Masukkan nomor BCA Virtual Account Anda, atau pilih dari Daftar Transfer',
        'Masukkan jumlah yang akan dibayarkan',
        'Masukkan PIN m-BCA Anda',
        'Transaksi telah berhasil',
      ],
    },
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'KlikBCA Pribadi',
      list: [
        'Silahkan login pada aplikasi KlikBCA Individual',
        'Masukkan User ID dan PIN Anda',
        'Pilih “Transfer Dana”',
        'Pilih “Transfer ke BCA Virtual Account”',
        'Masukkan nomor BCA Virtual Account Anda atau pilih dari Daftar Transfer',
        'Masukkan jumlah yang akan dibayarkan',
        'Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”            ',
        'Pembayaran telah selesai dilakukan',
      ],
    },
  ],
  bri_virtual_account: [
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'Transfer ATM',
      list: [
        'Pilih menu Transaksi Lain, kemudian pilih menu Pembayaran',
        'Setelah itu klik Menu Lainnya, lalu pilih menu Briva',
        'Masukkan nomor rekening dengan nomor Virtual Account Anda (contoh: 7810202001539202) dan pilih Benar',
        'Ketika muncul konfirmasi pembayaran, silahkan pilih "Ya"',
        'Voila! Transaksi telah selesai dan silahkan ambil bukti pembayaran anda',
      ],
    },
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'Internet Banking',
      list: [
        'Silahkan login Internet Banking, kemudian pilih Menu Pembayaran',
        'Lalu pilih menu Briva',
        'Masukkan nomor rekening dengan nomor Virtual Account Anda (contoh: 7810202001539202) kemudian klik Kirim',
        'Setelah itu, masukkan password serta mToken internet banking',
      ],
    },
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'Mobile Banking',
      list: [
        'Silahkan login Mobile Banking, lalu pilih menu Pembayaran',
        'Setelah itu klik menu Briva',
        'Masukkan nomor rekening dengan nomor Virtual Account Anda (contoh: 7810202001539202) dan jangan lupa tuliskan jumlah nominal tagihan yang ada di invoice',
        'Lalu masukkan PIN Mobile Banking dan klik Kirim',
        'Transaksi sudah selesai. Bukti pembayaran anda akan dikirimkan melalui notifikasi SMS',
      ],
    },
  ],
  mandiri_virtual_account: [
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'Transfer ATM',
      list: [
        'Pilih pembayaran melalui Mandiri Virtual Account.',
        'Catat kode pembayaran yang Anda dapat.',
        'Gunakan ATM mandiri untuk menyelesaikan pembayaran.',
        'Masukkan PIN Anda.',
        'Pilih Bayar/Beli, lalu pilih Lainnya.',
        'Pilih Multi Payment.',
        'Masukkan 70012 sebagai kode perusahaan Midtrans.',
        'Masukkan kode pembayaran yang Anda dapat sebelumnya, lalu pilih Benar.',
        'Pastikan detail pembayaran Anda benar & masukkan item pembayaran yang akan dibayar, lalu pilih Ya.',
        'Pembayaran Anda dengan Mandiri Bill Payment selesai.',
      ],
    },
    {
      title: 'Panduan Pembayaran melalui',
      titleHightlight: 'M-Banking',
      list: [
        'Buka aplikasi livin` by Mandiri',
        'Pilih icon "Bayar"',
        'Masukkan kode 70012 pada kolom "Cari penyedia jasa"',
        'Klik Midtrans',
        'Masukkan kode bayar yang muncul di halaman pembayaran',
      ],
    },
  ],
};

export const howToPayText = {
  mandiri_virtual_account: [
    'Pilih pembayaran melalui Mandiri Bill Payment.',
    'Catat kode pembayaran yang Anda dapat.',
    'Gunakan ATM mandiri untuk menyelesaikan pembayaran.',
    'Masukkan PIN Anda.',
    'Pilih ‘Bayar/Beli’, lalu pilih ‘Lainnya’.',
    'Pilih ‘Multi Payment’.',
    'Masukkan ‘70012’ sebagai kode perusahaan Midtrans.',
    'Masukkan kode pembayaran yang Anda dapat sebelumnya, lalu pilih ‘Benar’.',
    'Pastikan detail pembayaran Anda benar & masukkan item pembayaran yang akan dibayar, lalu pilih ‘Ya’.',
    'Pembayaran Anda dengan Mandiri Bill Payment selesai.',
  ],
  bni_virtual_account: [
    'Pilih pembayaran melalui Bank Transfer/Virtual Account.',
    'Catat 16 digit nomor virtual account & nominal pembayaran Anda.',
    'Gunakan ATM yang memiliki jaringan ATM Bersama/Prima/Alto untuk menyelesaikan pembayaran.',
    'Masukkan PIN Anda.',
    'Di menu utama pilih `Transaksi lainnya`.',
    "Pilih 'Transfer' lalu pilih 'Ke Rek Bank Lain'.",
    "Masukkan kode bank BNI '009' lalu pilih 'Benar'.",
    "Masukkan nominal pembayaran lalu pilih 'Benar'.",
    "Masukkan 16 digit virtual account tujuan pembayaran lalu pilih 'Benar'.",
    'Pilih rekening yang akan didebet.',
    "Pastikan nominal pembayaran & nomor virtual account sudah benar terisi, lalu pilih 'Benar'.",
    'Pembayaran Anda dengan BNI Virtual Account selesai.',
  ],
  permata_virtual_account: [
    'Pada menu utama, pilih “Transaksi Lainnya“',
    'Pilih “Pembayaran“',
    'Pilih “Pembayaran Lainnya“',
    'Pilih “Virtual Account”',
    'Masukkan 16 digit No. Virtual Account yang dituju, lalu tekan “Benar“',
    'Pada halaman konfirmasi transfer akan muncul jumlah yang dibayarkan, nomor rekening, & nama Merchant. Jika informasi telah sesuai tekan “Benar“',
    'Pilih rekening pembayaran Anda dan tekan “Benar“',
  ],
  indomaret: [
    'Setelah “Place Order” dengan Indomaret, kamu akan menerima kode pembayaran unik.',
    'Tunjukkan kode pembayaran unik ke kasir Indomaret terdekat',
    'Kasir Indomaret akan melakukan konfirmasi jumlah pembayaran dan nama merchant.',
    'Transaksi berhasil! Kamu akan mendapatkan email konfirmasi pembayaran dan simpanlah struk transaksi Indomaret kamu sebagai bukti pembayaran.',
  ],
  shopee_pay_web: [
    'Pastikan kamu sudah login di aplikasi SHOPEEPAY',
    'Buka aplikasi SHOPEE di smartphone kamu, pilih Pay',
    'Arahkan kamera ke Kode QR',
    'Periksa kembali detail pembayaran Anda di aplikasi SHOPEE dan pilih Pay',
    'Transaksi kamu selesai',
  ],
  shopee_pay_mobile_web: [
    'Klik "Place holder"',
    'Aplikasi Shopee akan terbuka',
    'Periksa kembali detail pembayaran kamu di aplikasi Shopee dan tekan Pay',
    'Transaksi kamu selesai',
  ],
  ovo: [
    'Pastikan kamu sudah login ke aplikasi OVO',
    'Pembayaran dengan OVO akan kedaluwarsa dalam 30 detik setelah kamu klik “Make Payment”',
    'Buka notifikasi OVO untuk melakukan pembayaran',
    'Pilih cara pembayaran dengan “OVO Cash” atau “OVO Point” atau kombinasi keduanya, kemudian klik “Bayar”',
  ],
  midtrans_kredivo: [
    'Masukkan kode OTP untuk melanjutkan pembayaran. Anda akan diarahkan kembali ke halaman Lilla.',
    'Pembayaran berhasil, pesanan kamu sedang diproses.',
  ],
  link_aja: [
    'Setelah “Make Payment” dengan LinkAja, kamu akan dialihkan ke halaman web pembayaran  LinkAja.',
    'Masukkan nomor LinkAja dan pin LinkAja kamu.',
    'Silakan masukkan kode OTP yang dikirimkan ke nomor LinkAja kamu untuk verifikasi pembayaran.',
    'Transaksi berhasil! Kamu akan mendapatkan email konfirmasi pembayaran.',
  ],
  gpn: [
    'Harap konfirmasikan pesanan Anda dan dapatkan kode pembayaran setelah klik tombol "Make Payment"',
  ],
  gopay: [
    'Klik Make Payment',
    'Aplikasi Gojek akan terbuka',
    'Periksa kembali detail pembayaran kamu di aplikasi Gopay dan tekan Pay',
    'Transaksi kamu selesai',
  ],
  bank_transfer: [
    'Untuk pembayaran melalui teller bank, isi “Nomor Rekening” dengan 000 dan “Nama Pemilik Rekening” dengan nama kamu.',
    'Simpan bukti transfer dan upload untuk mempercepat proses verifikasi pembayaran kamu.',
  ],
  atome: [
    'Pilih Atome sebagai metode pembayaran',
    'Pilih bayar dengan aplikasi atau lanjutkan di browser',
    'Masukan kode OTP (jika menggunakan browser)  ',
    'Pilih periode cicilan 3 atau 6 bulan dengan minimum transaksi Rp.50.000 ',
    'Periksa kembali pembayaran kamu di aplikasi dan tekan ajukan',
    'Khusus pengguna baru, lakukan registrasi KTP/data diri',
  ],
};
