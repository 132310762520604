/*
Author: Nishant Singh
Description: All method name should be followed by suffix Request
*/

const Config = require('~config/default.env').default;
import axios from 'axios';
const qs = require('qs');
// ...........Auth
const loginRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/login/mobile`,
    payload,
  );
};

const loginRequestWeb = (payload) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/login`, payload);
};

const facebookLoginRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/login/facebook`,
    payload,
  );
};

const signupRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/register/mobile`,
    payload,
  );
};

const signupRequestWeb = (payload) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/register`, payload);
};

const quickRegisterRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/quick-register`,
    payload,
  );
};

const quickRegisterResendRequest = (payload) => {
  return axios.put(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/quick-register/resend`,
    payload,
  );
};

const logoutRequest = (token) => {
  return axios.delete(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/logout/mobile`, {
    token,
  });
};

const logoutRequestWeb = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/logout`);
};

const featureToggleOtpRequest = (mode) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/feature-toggles/${mode}`);
};

const otpRequest = (payload) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/otp/code`, payload);
};

const loginOtpRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/login/otp/request`,
    payload,
  );
};

const loginOtp = (payload) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/login/otp`, payload);
};

const otpVerifyRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/otp/verify`,
    payload,
  );
};

const userNameSuggestionsRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/username/suggestions`,
    payload,
  );
};

const phoneCheckRequest = (payload) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/phone/check`, {
    params: { filter: payload },
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
};

const emailCheckRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/email/check`,
    payload,
  );
};

const updateUsernameRequest = (payload) => {
  return axios.put(`${Config.MS_SOCO_PUBLIC_API_URL}/auth/username`, payload);
};

// ..............Password
const forgetPasswordRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/forget-password`,
    payload,
  );
};

const resetPasswordRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/reset-password`,
    payload,
  );
};

const verifyResetPasswordRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/reset-password-check`,
    payload,
  );
};

const changePasswordRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/auth/change-password`,
    payload,
  );
};

// ..............user
const userDetailRequest = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me`);
};

const updateUserRequest = (id, payload) => {
  return axios.put(`${Config.MS_SOCO_PUBLIC_API_URL}/users/${id}`, payload);
};

const userPointsHistoryRequest = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me/points/history`);
};

const userBadgesRequest = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me/badges`);
};

const userBeautyProfileRequest = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me/beauty-profile`);
};

const userRelationsRequest = () => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/relations`);
};

const preferencesRequest = (payload) => {
  return axios.post(
    `${Config.MS_SOCO_PUBLIC_API_URL}/user/me/preferences`,
    payload,
  );
};

const userRequest = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/users`, { params });
};

const profileRequest = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/lilla-profile`, {
    params,
  });
};

const tagsRequest = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/tags`, { params });
};

const saveTagsRequest = (payload) => {
  return axios.post(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me/tags`, payload);
};

const isLillaMemberRequest = (params) => {
  return axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/visit/count`, {
    params,
  });
};

export const User = {
  loginRequest,
  loginRequestWeb,
  facebookLoginRequest,
  signupRequest,
  signupRequestWeb,
  logoutRequest,
  logoutRequestWeb,
  userNameSuggestionsRequest,
  phoneCheckRequest,
  emailCheckRequest,
  forgetPasswordRequest,
  userDetailRequest,
  otpRequest,
  featureToggleOtpRequest,
  otpVerifyRequest,
  quickRegisterRequest,
  quickRegisterResendRequest,
  updateUserRequest,
  userPointsHistoryRequest,
  userBadgesRequest,
  userBeautyProfileRequest,
  userRelationsRequest,
  resetPasswordRequest,
  verifyResetPasswordRequest,
  changePasswordRequest,
  preferencesRequest,
  userRequest,
  tagsRequest,
  profileRequest,
  saveTagsRequest,
  isLillaMemberRequest,
  updateUsernameRequest,
  loginOtpRequest,
  loginOtp,
};
